import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import PostPreview from '../components/post-preview';


const FilteredPosts = ({ data, pageContext, location }) => {

  //console.log({ data, pageContext, location });

  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const filterType = pageContext.title;

  let posts;
  let filteredValue;
  let filteredPostCount;
  const tags = data.sidebarData.tags;
  const categories = data.sidebarData.categories;
  const archives = data.sidebarData.archives;
  const searchQuery = undefined;
  const setSearchQuery = undefined;

  if (filterType === 'Tag') {
    posts = data.taggedPosts.nodes;
    filteredValue = pageContext.tag;
    filteredPostCount =  data.taggedPosts.totalCount;
  } else if (filterType === 'Category') {
    posts = data.categorizedPosts.nodes;
    filteredValue = pageContext.category;
    filteredPostCount =  data.categorizedPosts.totalCount;
  } else if (filterType === 'Archive') {
    posts = data.archivedPosts.nodes;
    filteredValue = pageContext.archive;
    filteredPostCount =  data.archivedPosts.totalCount;
  }

  return (
    <Layout location={location} title={siteTitle} searchQuery={searchQuery} setSearchQuery={setSearchQuery} tags={tags} categories={categories} archives={archives} hideSearch={true}>
      <Seo title={pageContext.title}
        description={pageContext.description} />
      <div style={{paddingBottom: '20px'}}>
        <h3>{pageContext.title}: {filteredValue}</h3>
        <p className="lead">{`${filteredPostCount} Post(s) found.`}</p>
        <hr />
      </div>
      {posts.map(post => {
        //console.log(post);
        const title = post.frontmatter.title || post.fields.slug;
        const postDescription = post.frontmatter.description;
        const readTime = post.fields.readingTime.text;
        return (
          <PostPreview
            title={title}
            slug={post.fields.slug}
            tags={post.frontmatter.tags}
            date={post.frontmatter.date}
            excerpt={postDescription}
            readTime={readTime}
            key={post.fields.slug} />
        )
      })}
    </Layout>
  );
};

export default FilteredPosts;
// , $archive: String
export const pageQuery = graphql`
  query($tag: String, $category: String, $archive: Date) {
    site {
      siteMetadata {
        title
      }
    }
    taggedPosts: allMarkdownRemark(
      limit: 100
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      nodes {
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
        }
      }
    }
    categorizedPosts: allMarkdownRemark(
      limit: 100
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      totalCount
      nodes {
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
        }
      }
    }
    archivedPosts: allMarkdownRemark(
      limit: 100
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { archive: { in: [$archive] } } }
    ) {
      totalCount
      nodes {
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
        }
      }
    }
    sidebarData: allMarkdownRemark(limit: 1000, sort: { fields: [frontmatter___date], order: DESC }) {
      tags: group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
      categories: group(field: frontmatter___categories) {
        category: fieldValue
        totalCount
      }
      archives: group(field: frontmatter___archive) {
        archive: fieldValue
        totalCount
      }
    }
  }
`;