import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const PostPreview = ({title, date, tags, readTime, excerpt, slug, image, author, profile}) => {
  //console.log({title, date, tags, readTime, excerpt, slug, image, author, profile});

  return (
    <article className="post" key={slug}>
      <div className="post-header">
        <h2 className="post-title">
          <Link to={slug}>{title}</Link>
        </h2>
        <ul className="post-meta">
          <li key="cal"><i className="icon-calendar"></i> {date}</li>
          <li key="tags"><i className="icon-price-tags"></i>
            {tags.map((tag, index) => {
              return (index <= 2 && <Link to={`/tags/${tag}`} key={tag}>{` ${tag}, `}</Link>);
            })}
          </li>
          <li key="readtime"><i className="icon-clock" ></i> {readTime}</li>
        </ul>
      </div>

      <div className="post-preview">
        <Link to={slug}>
          {image && <GatsbyImage image={getImage(image)} alt={title} quality={80} layout="fixed" formats={["AUTO", "WEBP"]} />}
        </Link>
      </div>

      <div className="post-content">
        <p>{excerpt}</p>
      </div>

      <div>
        <Link to={slug} className="btn btn-outline-custom" alt={`Read more about ${title}`} aria-label={`Read more about ${title}`}>Read More <i className="icon-arrow-right2"></i></Link>
      </div>
    </article>
  );
};

export default PostPreview;