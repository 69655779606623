import React from 'react';

const PageHeader = ({title, description}) => {

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-title">
              <div className="row">
                <div className="col-md-9 col-xs-12">
                  <h2><span>{title}</span></h2>
                  <p className="subtitle text-muted">{description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageHeader;