import React from 'react';

import Navbar from './navbar';
import PageHeader from './page-header';
import Sidebar from './sidebar';

const Layout = ({ searchQuery, setSearchQuery, children, tags, categories, archives, hideSearch }) => {
  return (
    <div id="wrapper">
      <Navbar />

      <div className="page-wrapper">
        <PageHeader title="Hey, Welcome..." description="Welcome to K2V Academy, where you can find stuff to learn about Development, Cloud, DevOps and everything in between." />
        <section className="mt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-8">
                {children}
              </div>

              <div className="col-xl-4">
                <Sidebar
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  tags={tags}
                  categories={categories}
                  archives={archives}
                  hideSearch={hideSearch} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Layout
